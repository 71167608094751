import React, {useContext, useMemo, useCallback, useReducer, useState, memo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FilterButton, FilterHeaderContainer } from '../Filter/FilterHeader';
import RefineSearch from '../RefineSearch';
import SortSelector from '../SortSelector';
import { ToolSet, ToolSetButton, ToolSetButtonTray, ToolSetInner, ToolSetHeader } from '../../../../components/ToolSet';
import { getFacets } from '../../../../store/actions';
import { setSearchTracking } from '../../../../store/actions/dataLayer';
import * as scroll from '../../../../utils/scrollTo';

import { MobileFilterContext } from '../../../../context/MobileFilterContext';

import { generateSearchPath, getDefaultParams,
  getActiveParams, getFormattedParamsArray, getNotEmptyActiveParams } from '../../../../utils/urlHelpers/boats';
import { PortalConfigContext } from '../../../../config/portal';
import SearchAlertsContainer from '../SearchAlerts/components/Boats';
import { Cookies } from 'react-cookie';
import { getCurrentLocale } from '../../../../utils/language';

import './styles.css';
import {asArray, yieldToMain} from '../../../../utils';
import { useNumberRangeErrors } from '../../../../hooks/useNumberRangeErrors';
import get from 'lodash/get';
import { getMessages } from '../../../../tppServices/translations/messages';

const reducer = (prevState, updated) => ({
  ...prevState,
  ...updated
});

const MobileRefineSearch = (props) => {
  const [state, setState] = useReducer(reducer, { data: null });
  const [open, setOpen] = useState(false);
  const context = useContext(PortalConfigContext);
  const { isThreeColumnLayout, match, history, seoMakeInfo, facets } = props;
  const loading = includes(get(props, 'componentWorking', []), 'facets');
  const urlParams = match?.params || {};
  const dispatch = useDispatch();
  const {urlCurrentState, activeParams, activeParamsCount, currentLanguage} = useMemo(() => {
    const urlCurrentState = getDefaultParams(urlParams);
    const activeParams = getActiveParams(urlCurrentState);
    const activeParamsCount = getFormattedParamsArray(activeParams).length;
    const currentLanguage = getCurrentLocale();
    return {urlCurrentState, activeParams, activeParamsCount, currentLanguage};
  },[urlParams]);
  const mobileParams = useMemo(() => {
    return state.data ? getNotEmptyActiveParams(state.data) : null;
  }, [state.data]);

  const {
    onError,
    getErrors,
    displayFormErrors,
    clearAllErrors: clearAllNumberRangeErrors,
    clearErrors: clearNumberRangeErrors,
  } = useNumberRangeErrors();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDataChange = useCallback(async (data) => {
    clearNumberRangeErrors(data);
    setState({ data });
    await yieldToMain();

    let params = urlCurrentState;
    let path = generateSearchPath(data, params, true);
    dispatch(getFacets(path, {}));
    props.handleDataChangeMobile(data);
  }, [props.handleDataChangeMobile]);

  const handleSubmit = () => {
    const hasError = displayFormErrors();
    if (hasError) {
      return;
    }

    requestData();
    dispatch(setSearchTracking(state.data, 'group'));
    setOpen(false);
    setState({data: null});
    scroll.scrollToTop();
  };

  const requestData = () => {
    history.push(generateSearchPath(state.data, urlCurrentState, true));
  };
  const messages = getMessages();
  const { formatMessage: t } = useIntl();
  const enableFilterRedesign = !!context.supports?.enableFilterRedesign;
  const models = asArray(facets?.makeModel);
  const searchAlerts = get(context.supports,'searchAlerts') &&
                         !get(context.languages[currentLanguage], 'disableSearchAlerts', false);

  return (
  <MobileFilterContext.Provider value={{ onError, allErrors: getErrors() }}>
    <div className={classnames('mobile-search-filters', { 'filter-v2': enableFilterRedesign, loading, 'new-filter-bar': isThreeColumnLayout })}>
      <ToolSet open={open}>
      {enableFilterRedesign && <ToolSetHeader onClick={ handleClose } filterText={t(messages.filter)} />}
        <ToolSetInner>
          { loading && <div className="spinner-preloader" /> }
          <RefineSearch
            match={props.match}
            componentWorking={props.componentWorking}
            facets={props.facets}
            initialState={props.initialState}
            mode={props.mode}
            onDataChange={ handleDataChange }
            position="mobile"
            cookies={props.cookies}
            mobileParams={mobileParams}
            customUom={props.customUom}
            isThreeColumnLayout={isThreeColumnLayout}
            userCurrency={props.userCurrency}
            seoMakeInfo={seoMakeInfo}
            abTestContext={props.abTestContext}
            onClearErrors={ clearAllNumberRangeErrors }

            // mockMobileRefineSearch and handleDataChangeMobile is only used in unit test
            // 'renders and fires event on mobile' and should be removed
            mockMobileRefineSearch={props.mockMobileRefineSearch}
            handleDataChangeMobile={props.handleDataChangeMobile}
            // dont use {...props} here, only pass the props that are needed!
          />
        </ToolSetInner>
        <ToolSetButtonTray>
          { searchAlerts && <SearchAlertsContainer
            cookies={props.cookies}
            match={props.match}
            eventCategory="above search filter"
            active={mobileParams || activeParams}
            makeModel={models}
            seoMakeInfo={seoMakeInfo}
          /> }
          {!enableFilterRedesign &&
            <ToolSetButton name="cancel" hidden={state.data} onClick={ handleClose }>
              {t(messages.cancel)}
            </ToolSetButton>
          }
          <ToolSetButton name="submit" hidden={!state.data && !enableFilterRedesign} onClick={ handleSubmit }>
            {t(messages.searchTitle)}
          </ToolSetButton>
        </ToolSetButtonTray>
      </ToolSet>
      <div className="mobile-filter-anchor"></div>
      <div className={classnames('mobile-filter-header', { 'no-search-alert': !searchAlerts })}>
        <FilterHeaderContainer type="light">
          <FilterButton onClick={ handleOpen } count={activeParamsCount} />
          {
            searchAlerts &&
            <SearchAlertsContainer
              cookies={props.cookies}
              match={props.match}
              eventCategory="sort bar"
              active={mobileParams || activeParams}
              makeModel={models}
              seoMakeInfo={seoMakeInfo}
            />
          }
          <SortSelector
            match={props.match}
            isBranded={props.isBranded}
            history={props.history}
            scrollTo=".mobile-filter-anchor"
            params={props.params}
          />
        </FilterHeaderContainer>
      </div>
    </div>
  </MobileFilterContext.Provider>
  );
};

MobileRefineSearch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChangeMobile: PropTypes.func,
  isThreeColumnLayout: PropTypes.bool,
  facets: PropTypes.object,
  seoMakeInfo: PropTypes.object,
  componentWorking: PropTypes.arrayOf(PropTypes.string),
  initialState: PropTypes.string,
  mode: PropTypes.any,
  mobileParams: PropTypes.object,
  userCurrency: PropTypes.string,
  isBranded: PropTypes.bool,
  params: PropTypes.object,
  // mockMobileRefineSearch is used only in test 'renders and fires event on mobile' must be changed, don't put all props {...props} to components
  mockMobileRefineSearch: PropTypes.bool,
  abTestContext: PropTypes.object,
};

export default memo(MobileRefineSearch, isEqual);
