import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LocationFilter } from '@dmm/lib-react-ui-components';
import { getRadiuses } from '../../../utils/radiusSearch';
import { getSuggestionsLocations } from '../../../../../requests/locationsRequest';
import { syncTranslationElements } from '../../../../../tppServices/translations/intlManager';

const FilterSuggestLocation = ({ context, isFilterLoctionSelected = false }) => {
  const [searchOptions, setSearchOptions] = useState([]);
  const inputRef = useRef(null);
  const typingTimeoutRef = useRef(null);

  const { formatMessage: t, messages } = syncTranslationElements();
  const translations = useMemo(() => ({
    any: t(messages.radius.any),
    all: t(messages.all),
    location: t(messages.location),
    radius: t(messages.byRadius),
    locationCurrrent: t(messages.radius.locationCurrent),
    notFound: t(messages.radius.errorNotFound),
    locationPlaceholder: t(messages.radius.locationPlaceholder),
    locationDisabled: t(messages.radius.errorLocationDisabled),
    myLocation: t(messages.radius.myLocation),
    pleaseSelect: t(messages.radius.pleaseSelect)
  }), []);

  const radiuses = useMemo(() => (
    getRadiuses().map(item => item.label === translations.all ? translations.any : item.label)
  ), []);

  const fetchSuggestions = useCallback(async (searchQuery) => {
    try {
      const suggestions = await getSuggestionsLocations(searchQuery, context);
      setSearchOptions(suggestions?.locationsArray);
    } catch (error) {
      /* eslint-disable no-console */
      console.error("Error fetching location suggestions:", error);
      setSearchOptions([]);
    }
  }, [context]);

  const handleInputChange = useCallback((event) => {
    const value = event.target.value;
    /* istanbul ignore if */
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 3) {
        fetchSuggestions(value);
      }
    }, 300);
  }, [fetchSuggestions]);

  const handleLocationChange = useCallback((event) => {
    const value = event?.target?.value || event;
    const inputElement = document.getElementById('searchInput');
    inputElement.value = translations.myLocation;
    /* eslint-disable no-console */
    console.log('Location :', { coords: value });
  }, []);

  useEffect(() => {
    const findAndWatchInput = () => {
      const inputElement = document.getElementById('searchInput');
      inputRef.current = inputElement;
      inputElement.addEventListener('input', handleInputChange);
    };

    findAndWatchInput();

    return () => {
      /* istanbul ignore next */
      if (inputRef.current) {
        inputRef.current.removeEventListener('input', handleInputChange);
      }
      /* istanbul ignore if */
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  return (
    <LocationFilter
      heading={translations.location}
      radiusDefaultText={isFilterLoctionSelected ? translations.pleaseSelect : translations.any}
      radiusLabel={translations.radius}
      radiusOptions={radiuses}
      onChangeRadius={
        /* istanbul ignore next */
        (index, option) => {
            /* eslint-disable no-console */
            console.log(index, option);
          }
        }
      currentLocationText={translations.locationCurrrent}
      locationDeniedAlertText={translations.locationDisabled}
      onLocationChange={handleLocationChange}
      searchNotFoundText={translations.notFound}
      searchOptions={searchOptions}
      searchPlaceholder={translations.locationPlaceholder}
    />
  );
};

FilterSuggestLocation.propTypes = {
  context: PropTypes.object.isRequired,
  isFilterLoctionSelected: PropTypes.bool,
};

export default React.memo(FilterSuggestLocation);
