import * as httpClient from '../utils/httpClient';

export const getSuggestionsLocations = async (location, requestContext) => {
  try {
    const requestData = { params: {location} };
    const apiClient = httpClient.getHttpClient(requestContext?.headers);
    const locationsResponse = await apiClient.get('/location/suggestions', requestData);
    const locationsArray = locationsResponse?.data?.Results.map(item =>
        `${item.Place.Text}, ${item.Place.Country}`
      ) || [];
    return { locationsArray, error: null };
  } catch (err) {
    return { locationsArray: [], error: err };
  }
};
