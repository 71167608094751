import React, {useEffect, useMemo, useReducer, memo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ToolSet, ToolSetInner } from '../../../../components/ToolSet';
import RefineSearch from '../RefineSearch';
import { generateSearchPath, getDefaultParams } from '../../../../utils/urlHelpers/boats';
import { getParamsFromLocationAndHistoryQueryString } from '../../../../utils/featureToggles';
import stringify from 'qs/lib/stringify';
import { Cookies } from 'react-cookie';
import isEqual from 'lodash/isEqual';

import './styles.css';
import {yieldToMain} from '../../../../utils';
const reducer = (prevState, updated) => ({
  ...prevState,
  ...updated
});

const RefineSearchSideBar = (props) => {
  const [state, setState] = useReducer(reducer, { data: null });
  const {match, location, history} = props;
  const urlParams = match?.params || {};
  const urlGeneratingParams = useMemo(() => {
    let otherParams = getParamsFromLocationAndHistoryQueryString({
      location: location || {},
      history: history
    });
    let params = getDefaultParams(urlParams);
    return {params, otherParams};
  }, [urlParams, location, history]);
  useEffect(() => {
    if (!state.data) {
      return;
    }
    const requestData = async () => {
      let {params, otherParams} = urlGeneratingParams;
      let newPath = generateSearchPath(state.data, {...params}, true);
      await yieldToMain();
      if (Object.keys(otherParams).length > 0) {
        newPath = `${newPath}?${stringify(otherParams)}`;
      }
      history.push(newPath);
    };
    requestData();
  }, [state.data]);

  const handleDataChange = (data) => {
    setState({ data });
    reset();
  };
  const reset = () => {
    setState({ data: null });
  };

  return (
    <div id="refine-search" className={classnames('search-tools refine-search', { submittable: state.data })}>
      <ToolSet>
        <ToolSetInner>
          <RefineSearch
            match={props.match}
            componentWorking={props.componentWorking}
            facets={props.facets}
            initialState={props.initialState}
            mode={props.mode}
            onDataChange={handleDataChange}
            position="desktop"
            cookies={props.cookies}
            customUom={props.customUom}
            isThreeColumnLayout={props.isThreeColumnLayout}
            userCurrency={props.userCurrency}
            seoMakeInfo={props.seoMakeInfo}
            abTestContext={props.abTestContext}
          />
        </ToolSetInner>
      </ToolSet>
    </div>
  );
};

RefineSearchSideBar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.object,
  componentWorking: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.object,
  facets: PropTypes.object,
  initialState: PropTypes.string,
  mode: PropTypes.any,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  isThreeColumnLayout: PropTypes.bool,
  userCurrency: PropTypes.string,
  seoMakeInfo: PropTypes.object,
  abTestContext: PropTypes.object
};

export default memo(RefineSearchSideBar, isEqual);
